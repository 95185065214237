import { BaseTemplate, PageHead, Text } from '@myob/myob-widgets';
import { useParams } from 'react-router-dom';
import { SupportTicketWrapper } from './styles';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AppThunkDispatch, RootState } from '../stores';
import { useDispatch, useSelector } from 'react-redux';
import { HttpStatus } from 'helpers/request';
import Forbidden from 'components/Forbidden';
import ErrorComponent from 'components/ErrorComponent';
import LoadingWrapper from 'components/LoadingWrapper';
import { BookingDetailsState, fetchBookingDetailsAsync } from './reducers/getBookingDetails';
import { PremiumSupportBookingForm } from './components/PremiumSupportBookingForm';
import { getDateAndTimeFromScheduledTime } from '../helpers/tools';
import { helper } from '@my-account/tools';
import { ModalBox } from '../components/PopUpModal';
import { navigateToUrl } from 'single-spa';
import { cancelBooking, CancelBookingState } from './reducers/cancelBooking';
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account';
import { FailedBooking } from './components/FailedBooking';

import { SuccessfulBooking } from './components/SuccessfulBooking';

export const PremiumSupportBookingDetails: React.FC = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  const { id: bookingId } = useParams<{ id: string }>();
  const {
    fetch: { isLoading, data: bookingDetails, isError, error },
  } = useSelector<RootState, BookingDetailsState>((state) => state.bookingDetails);

  const {
    cancel: { isLoading: cancelIsLoading, isError: cancelIsError },
  } = useSelector<RootState, CancelBookingState>((state) => state.cancelBooking);

  const [showModal, setShowModal] = useState(false);
  const [showPremiumSupportBookingDetail, setShowPremiumSupportBookingDetail] = useState(true);

  const { selected: selectedAccount, error: accountSelectError } =
    useContext<AccountSelectContextValue>(AccountSelectContext);

  useEffect(() => {
    dispatch(fetchBookingDetailsAsync(bookingId));
  }, []);

  const handleGoBack = () => {
    setShowModal(false);
  };
  const goHomePage = () => {
    navigateToUrl('/account');
  };
  const handleCancelBooking = async () => {
    setShowModal(false);
    setShowPremiumSupportBookingDetail(false);
    await dispatch(cancelBooking(bookingId));
  };

  const content = useMemo(() => {
    if (error.status === HttpStatus.Forbidden) {
      return <Forbidden pageTitle="" title="You don’t have access to the request" description="" showAction={false} />;
    }
    if (isError) {
      return <ErrorComponent pageTitle="" />;
    }
    if (showPremiumSupportBookingDetail && bookingDetails) {
      const { date, time } = getDateAndTimeFromScheduledTime(
        bookingDetails.booking.scheduledTime,
        helper.getRegionFromTimezone(),
      );
      const bookingData = {
        name: bookingDetails.booking.fullName,
        emailAddress: bookingDetails.booking.email,
        contactPhoneNumber: bookingDetails.booking.phoneNumber,
        serialNumber: bookingDetails.booking.serialNumber,
        description: bookingDetails.booking.issueDescription,
        date: date,
        availableTime: time,
        status: bookingDetails.booking.status,
      };
      return (
        <SupportTicketWrapper data-testid="supportTicketWrapper">
          <PageHead title="Your Priority Support booking" className="page-head" />
          <Text marginBottom="xl">See details of your Priority Support booking below.</Text>
          <SupportTicketWrapper data-testid="supportTicketWrapper">
            <PremiumSupportBookingForm
              className="premium-support-booking-form"
              onCancelBooking={() => setShowModal(true)}
              bookingData={bookingData}
            />
          </SupportTicketWrapper>
        </SupportTicketWrapper>
      );
    } else if (cancelIsError) {
      return (
        <FailedBooking
          onClick={() => {
            setShowPremiumSupportBookingDetail(true);
            dispatch(fetchBookingDetailsAsync(bookingId));
          }}
          message="Booking cancellation has failed. Please try again later."
        />
      );
    } else {
      return (
        <SuccessfulBooking onClick={() => goHomePage()} title="Priority Support booking cancelled successfully." />
      );
    }
  }, [isError, error, bookingDetails, selectedAccount, showPremiumSupportBookingDetail, cancelIsError]);

  return (
    <div>
      {showModal && (
        <ModalBox
          onDiscard={handleCancelBooking}
          onGoBack={handleGoBack}
          title="Cancel booking request?"
          message="Are you sure you want to cancel your Priority Support booking?"
          discardButtonName="Cancel booking"
        />
      )}
      <BaseTemplate data-testid="support-request-details">
        <LoadingWrapper isLoading={isLoading}>{content}</LoadingWrapper>
      </BaseTemplate>
    </div>
  );
};
