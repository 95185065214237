import { splitToggles } from '@my-account/tools';

export const featureFlags = {
  isCommunicationHistoryDisplay: (): boolean =>
    splitToggles.isToggleOn('myaccount-digital-case-section-communication-history'),
};

export const premiumFeatureFlag = {
  isPremiumCustomer: (): boolean => splitToggles.isToggleOn('myaccount-home-premium-support'),
  isPremiumHelpLinkDisplay: (): boolean => splitToggles.isToggleOn('myaccount-home-premium-support-help-link'),
};
