import { StarOutlineIcon } from '@myob/myob-widgets';
import { Region } from '../type';
import useRegion from 'hooks/useRegion';
import { auConfig, nzConfig } from 'config';
import { PremiumSupportNotificationWrapper } from '../styles';
import { premiumFeatureFlag } from '../../configs/FeatureFlags';

export default function PremiumSupportNotification() {
  const region = useRegion(Region.AU);
  const config = region === Region.AU ? auConfig : nzConfig;
  return (
    <PremiumSupportNotificationWrapper>
      <div className="premium-contact-support-notification">
        <div className="premium-support-icon">
          <StarOutlineIcon data-testid="star-outline-icon" color="brand" />
        </div>
        <div className="premium-support-description" data-testid="premium-support-description">
          As part of your AccountRight subscription, you receive higher priority queue placement when contacting
          support.
          {premiumFeatureFlag.isPremiumHelpLinkDisplay() && (
            <a href={config.CONTACT_PREMIUM_SUPPORT_LINK} target="_blank" rel="noreferrer">
              {' '}
              Learn more about Priority Support.
            </a>
          )}
        </div>
      </div>
    </PremiumSupportNotificationWrapper>
  );
}
