import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ButtonRow,
  CalendarIcon,
  ClockIcon,
  Input,
  Select,
  Separator,
  Text,
  TextArea,
} from '@myob/myob-widgets';
import { PREMIUM_SUPPORT_BOOKING_FORM_PROPERTIES } from '../constants/constants';
import {
  AvailableSlotsInfo,
  BookingInfo,
  BookingStatus,
  PremiumSupportBookingInfo,
  Region,
  UserProfileInfo,
} from '../type';
import { helper } from '@my-account/tools';
import useRegion from 'hooks/useRegion';
import { forIn, values } from 'lodash';
import { getTimezoneOffset } from '../../helpers/tools';

type PremiumSupportBookingFormProps = {
  onSave?: (premiumSupportBookingFormData: PremiumSupportBookingInfo) => void;
  onCancel?: () => void;
  availableSlots?: AvailableSlotsInfo;
  bookingData?: BookingInfo;
  onCancelBooking?: () => void;
} & Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>;

export const PremiumSupportBookingForm: React.FC<PremiumSupportBookingFormProps> = ({
  onSave,
  onCancel,
  availableSlots,
  className,
  bookingData,
  onCancelBooking,
}) => {
  const region = useRegion(Region.AU);
  const [rules, setRules] = useState(PREMIUM_SUPPORT_BOOKING_FORM_PROPERTIES);
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [userProfile] = useState<UserProfileInfo>(JSON.parse(localStorage.getItem('USER_PROFILE')) ?? {});

  const { firstName = '', lastName = '', email = '' } = userProfile;
  const fullName = `${firstName} ${lastName}`.trim();
  const formDataPreFill = {
    date: availableSlots?.datesAndTimeSlots[0]?.date || '',
    availableTime: availableSlots?.datesAndTimeSlots[0]?.availableSlots[0] || '',
    name: fullName,
    emailAddress: email,
    contactPhoneNumber: helper.getRegionFromTimezone() === 'NZ' ? '+64' : '+61',
    serialNumber: '',
    description: '',
  };

  const initState = useMemo(() => {
    return {
      formData: {
        date: availableSlots?.datesAndTimeSlots[0]?.date || '',
        availableTime: availableSlots?.datesAndTimeSlots[0]?.availableSlots[0] || '',
        name: fullName,
        emailAddress: email,
        contactPhoneNumber: helper.getRegionFromTimezone() === 'NZ' ? '+64' : '+61',
        serialNumber: '',
        description: '',
      },
      formDataPreFill: formDataPreFill,
      errors: {
        date: {
          isValid: false,
          value: '',
        },
        availableTime: {
          isValid: false,
          value: '',
        },
        contactPhoneNumber: {
          isValid: false,
          value: '',
        },
        serialNumber: {
          isValid: false,
          value: '',
        },
        description: {
          isValid: false,
          value: '',
        },
      },
    };
  }, []);

  const [premiumSupportBookingFormData, setPremiumSupportBookingFormData] = useState<PremiumSupportBookingInfo>(
    bookingData || initState.formData,
  );
  const [errors, setErrors] = useState({ ...initState.errors });

  useEffect(() => {
    setDescriptionLength(premiumSupportBookingFormData.description.length);
  }, [premiumSupportBookingFormData.description]);

  const formatDate = (date: string) => {
    return new Date(date)
      .toLocaleDateString('en-GB', {
        weekday: 'long',
        day: '2-digit',
        month: 'long',
      })
      .replace(',', '');
  };

  const dateOptions = () => {
    const options = isViewBooking ? (
      <Select.Option key={bookingData.date} value={bookingData.date} label={formatDate(bookingData.date)} />
    ) : (
      availableSlots?.datesAndTimeSlots.map((slot) => (
        <Select.Option key={slot.date} value={slot.date} label={formatDate(slot.date)} />
      ))
    );
    return (
      <>
        <Select.Option key="placeholder" value="" label="" hidden />
        {options}
      </>
    );
  };

  const timeOptions = (date: string) => {
    const options = isViewBooking
      ? isViewBooking && (
          <Select.Option
            key={bookingData.availableTime}
            value={bookingData.availableTime}
            label={bookingData.availableTime}
          />
        )
      : date &&
        availableSlots?.datesAndTimeSlots
          .find((item) => item.date === date)
          .availableSlots.map((item) => <Select.Option key={item} value={item} label={item} />);

    return (
      <>
        <Select.Option key="placeholder" value="" label="" hidden />
        {options}
      </>
    );
  };
  const setObjectState = (setState: React.Dispatch<React.SetStateAction<any>>, data: any) => {
    setState((preState) => ({
      ...preState,
      ...data,
    }));
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setObjectState(setPremiumSupportBookingFormData, { [name]: value });
  };
  const handleOnDateChange = (e) => {
    setPremiumSupportBookingFormData((prevState) => ({
      ...prevState,
      availableTime: '',
    }));

    handleOnChange(e);
  };
  const handleOnSerialNumberChange = (e) => {
    e.target.value = e.target.value.replace(/[^\d]/g, '');
    handleOnChange(e);
  };
  const handleOnPhoneNumberChange = (e) => {
    e.target.value = '+' + e.target.value.replace(/[^\d]/g, '');
    handleOnChange(e);
  };
  const handleOnBlur = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    validateForm(name, value);
  };
  const validateForm = (name: string, value?: string) => {
    if (name in rules) {
      const rule = rules[name];
      const phoneRegEx = /^\+[1-9]\d{10,14}$/;
      if (
        (rule.requiredLabel && !value) ||
        (rule.length && value.length !== 0 && value.length !== rule.length) ||
        (rule.phoneNumberFormat && !phoneRegEx.test(value))
      ) {
        setErrors((prevState) => ({ ...prevState, [name]: { isValid: false, value: rule.errorMessage } }));
      } else {
        setErrors((prevState) => ({ ...prevState, [name]: { isValid: true, value: '' } }));
      }
    }
  };

  const handleOnTimeChange = (e) => {
    handleOnChange(e);
  };

  const handleOnSave = () => {
    forIn(premiumSupportBookingFormData, (value, key) => {
      setObjectState(setPremiumSupportBookingFormData, { [key]: value.trim() });
      validateForm(key, value.trim());
    });
    saveData();
  };

  const useSyncCallback = (callback) => {
    const [proxyState, setProxyState] = useState({ current: false });
    const [params, setParams] = useState([]);

    const Func = useCallback(
      (...args) => {
        setParams(args);
        setProxyState({ current: true });
      },
      [proxyState],
    );

    useEffect(() => {
      if (proxyState.current === true) setProxyState({ current: false });
    }, [proxyState]);

    useEffect(() => {
      proxyState.current && callback(...params);
    });

    return Func;
  };

  const saveData = useSyncCallback(() => {
    const isValid = values(errors).filter((item) => !item.isValid).length === 0;
    if (isValid) {
      onSave(premiumSupportBookingFormData);
    }
  });

  const displayTimeZone = (region: string, date: string) => {
    const offset = getTimezoneOffset(region, date);
    if (region === Region.NZ) {
      return `All times are showed in (UTC${offset}) Auckland, Wellington`;
    } else {
      return `All times are showed in (UTC${offset}) Canberra, Melbourne, Sydney`;
    }
  };

  const isViewBooking = bookingData != null;
  return (
    <div className={className}>
      <div>
        <Text marginBottom="xs" fontSize="lg">
          Booking time
        </Text>
        <Select
          id="date"
          name="date"
          label="Date"
          width="lg"
          requiredLabel={rules.date.requiredLabel}
          value={premiumSupportBookingFormData.date}
          onChange={handleOnDateChange}
          prefixIcon={<CalendarIcon />}
          disabled={isViewBooking || premiumSupportBookingFormData.date === ''}
          errorMessage={errors.date.value}
          onBlur={handleOnBlur}
        >
          {dateOptions()}
        </Select>

        <Select
          id="available-time"
          name="availableTime"
          label="Available time"
          width="sm"
          requiredLabel={rules.availableTime.requiredLabel}
          value={premiumSupportBookingFormData.availableTime}
          onChange={handleOnTimeChange}
          disabled={isViewBooking || premiumSupportBookingFormData.date === ''}
          errorMessage={errors.availableTime.value}
          onBlur={handleOnBlur}
        >
          {timeOptions(premiumSupportBookingFormData.date)}
        </Select>

        <div className="timezone">
          <ClockIcon />
          <Text marginBottom="clear" marginLeft="sm" tone="neutral">
            {displayTimeZone(helper.getRegionFromTimezone(), premiumSupportBookingFormData.date)}
          </Text>
        </div>
      </div>

      <Separator />

      <div>
        <Text marginBottom="xs" fontSize="lg">
          Your detail
        </Text>

        <Input label="Name" name="contactName" value={premiumSupportBookingFormData.name} disabled />
        <Input
          label="Email address"
          name="contactEmailAddress"
          value={premiumSupportBookingFormData.emailAddress}
          disabled
        />

        <Input
          label="Serial number"
          name="serialNumber"
          width="md"
          maxLength={rules.serialNumber.length}
          onChange={handleOnSerialNumberChange}
          errorMessage={errors.serialNumber.value}
          onBlur={handleOnBlur}
          value={premiumSupportBookingFormData.serialNumber}
          disabled={isViewBooking}
        />
        <Input
          label="Contact phone number"
          name="contactPhoneNumber"
          width="md"
          maxLength={rules.contactPhoneNumber.maxLength}
          value={premiumSupportBookingFormData.contactPhoneNumber}
          requiredLabel={rules.contactPhoneNumber.requiredLabel}
          onBlur={handleOnBlur}
          onChange={handleOnPhoneNumberChange}
          errorMessage={errors.contactPhoneNumber.value}
          disabled={isViewBooking}
        />
        <>
          <div className="text-box">
            <TextArea
              label="What would you like help with?"
              name="description"
              rows={4}
              requiredLabel={rules.description.requiredLabel}
              maxLength={rules.description.maxLength}
              value={premiumSupportBookingFormData.description}
              errorMessage={errors.description.value}
              onBlur={handleOnBlur}
              onChange={handleOnChange}
              disabled={isViewBooking}
            />
            <span className="word-counter">
              {descriptionLength}/{rules.description.maxLength}
            </span>
          </div>
        </>
        {!isViewBooking && (
          <>
            <div className="security-reminder">
              Don&apos;t include your password or payment details. We&apos;ll never ask for this information in a
              message or email.
            </div>

            <span className="view-private-policy">
              View our{' '}
              <a href={`https://www.myob.com/${region.toLowerCase()}/privacy-policy`} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>{' '}
              to see how we look after your information.
            </span>
            <ButtonRow className="btn-row">
              <Button type="secondary" label="Cancel" onClick={onCancel}>
                Cancel
              </Button>
              <Button label="Request booking" onClick={handleOnSave}>
                Request booking
              </Button>
            </ButtonRow>
          </>
        )}

        {isViewBooking && bookingData.status === BookingStatus.New && (
          <ButtonRow className="btn-row">
            <Button type="secondary" label="Back" onClick={onCancelBooking}>
              Cancel booking
            </Button>
          </ButtonRow>
        )}
      </div>
    </div>
  );
};
