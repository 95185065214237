import React, { useEffect, useState } from 'react';
import { BaseTemplate, Button, PageState } from '@myob/myob-widgets';
import success from '../../assets/images/success.svg';
import { DescriptionWrapper } from '../styles';
import PremiumSupportNotification from './PremiumSupportNotification';
import { premiumFeatureFlag } from '../../configs/FeatureFlags';

type SuccessfulProps = {
  onClick: () => void;
};

export const Successful: React.FC<SuccessfulProps> = ({ onClick }) => {
  const [premiumUser, setPremiumUser] = useState<boolean>(localStorage.getItem('isPremiumCustomer') === 'true');

  useEffect(() => {
    setPremiumUser(localStorage.getItem('isPremiumCustomer') === 'true');
  }, [localStorage.getItem('isPremiumCustomer')]);

  return (
    <>
      <BaseTemplate>
        <PageState
          title="Thanks for contacting MYOB support"
          actions={[
            <Button key="return-to-my-account" type="primary" onClick={onClick}>
              Return to My Account
            </Button>,
          ]}
          description={
            <DescriptionWrapper>
              <div className="description_success_title">We look forward to helping you as soon as we can. </div>
              <div>
                We&apos;ll send you updates about your support request to the email you use to log in to your MYOB
                account.
              </div>
            </DescriptionWrapper>
          }
          image={<img src={success} alt="success" />}
        />
        {premiumUser ? <PremiumSupportNotification /> : null}
      </BaseTemplate>
    </>
  );
};
