import { matchPath } from 'react-router-dom';
import { ERoutePath, ERoutePathAndPageNameMap } from 'router';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import { Region } from '../modules/type';

export const removeQueryString = (origin: string): string => {
  let url: URL;
  try {
    url = new URL(origin);
  } catch {
    return '';
  }
  return url.origin + url.pathname;
};

export const openInNewTab = (url: string): void => {
  window.open(url, '_blank');
};

export const computePageNameFromPath = (path: string): string => {
  const pathName = path.split('?')[0];
  const allInternalRoutePaths = Object.values(ERoutePath);
  const defaultPageName = 'Unnamed';

  for (let index = 0; index < allInternalRoutePaths.length; index++) {
    const routePath = allInternalRoutePaths[index];
    const matchedPath = matchPath(pathName, { path: routePath, strict: true, exact: true });
    if (matchedPath) {
      return ERoutePathAndPageNameMap.get(routePath);
    }
  }

  return defaultPageName;
};

export const getMyobVisitorId = (): string => {
  return Cookies.get('myob_visitor_id') || '';
};

export const isBelongToERoutePath = (path: string): boolean => {
  const pathName = path.split('?')[0];
  const allRoutePaths = Object.values(ERoutePath);

  for (let index = 0; index < allRoutePaths.length; index++) {
    if (matchPath(pathName, { path: allRoutePaths[index], strict: true })) {
      return true;
    }
  }

  return false;
};

export const getZoneIdFromRegion = (region: string): string => {
  return region === Region.NZ ? 'Pacific/Auckland' : 'Australia/Sydney';
};

export const getScheduledTime = (date: string, time: string, region: string): string => {
  return moment.tz(`${date} ${time}`, getZoneIdFromRegion(region)).format();
};

export const getTimezoneOffset = (region: string, date: string): string => {
  return moment.tz(`${date}`, getZoneIdFromRegion(region)).format('Z');
};

export const getDateAndTimeFromScheduledTime = (
  scheduledTime: string,
  region: string,
): { date: string; time: string } => {
  const zoneId = getZoneIdFromRegion(region);
  const date = moment.tz(scheduledTime, zoneId).format('YYYY-MM-DD');
  const time = moment.tz(scheduledTime, zoneId).format('HH:mm');
  return { date, time };
};
